import { xpTheme } from "./xpTheme";
import { center, space18 } from "../dist/helpers";

export default function Win98theme() {
    return (
      <>
        { center(<h2>Win 98 Theme</h2>) }
        <div>
            <link rel="stylesheet" href="https://unpkg.com/98.css" />
            { xpTheme(false) }
            { space18 }
        </div>
      </>
    );
  }