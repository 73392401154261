import React from 'react';
import {useEffect} from 'react';

import './index.css';
import { BrowserRouter, Routes } from "react-router-dom";
import { routes } from "./routes/routes"
import ScrollToTop from "./ScrollToTop"
import { useState } from "react"
import ADNavbar from "./dist/ADNavbar"
import { ConditionalSnow } from './dist/helpers';

export default function Index() {
  const systemDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [isDarkModeOverride, setDarkModeOverride] = useState(systemDarkMode);
  // TODO - Add the ability such that when the current month falls between Nov through March auto on
  const [snowIsEnabled, setSnowIsEnabled] = useState(false);

  const darkModeToggle = () => (
    <button
      className={`btn`}
      type="button"
      onClick={() => setDarkModeOverride(!isDarkModeOverride)}
      style={{ marginTop: "0rem" }}
    >
      { isDarkModeOverride ? "☀️" : "🌙" }
    </button>
  );

  const snowToggle = () => (
    <button
      className={`btn`}
      type="button"
      onClick={() => setSnowIsEnabled(!snowIsEnabled)}
      style={{ marginTop: "0rem" }}
    >
      { snowIsEnabled ? "☁️" : "❄️" }
    </button>
  );


  // This is for determining distance from top of page
  let [shouldRemoveTransparent, setShouldRemoveTransparent] = useState(false);

  useEffect(() => {
    const handleScroll = event => {
      // console.log('window.scrollY', window.scrollY);
      const distanceFromTop = document.documentElement.scrollTop
      if (distanceFromTop > 70) {
        setShouldRemoveTransparent(true);
      } else {
        setShouldRemoveTransparent(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  return (
    <div
      style={{ backgroundColor: isDarkModeOverride ? "rgb(105, 159, 201)" : "#A0CFEB" }}>
      <React.StrictMode>
        <BrowserRouter>
          <ScrollToTop />
          <br/>
          <ADNavbar {...{
            isDarkModeOverride,
            darkModeToggle,
            shouldRemoveTransparent,
            snowToggle
          }} />
          { ConditionalSnow(snowIsEnabled) }
          { ConditionalSnow(snowIsEnabled) }
          <Routes>
            { routes(isDarkModeOverride) }
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </div>
  )
};
