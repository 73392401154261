import { space18, BWHeader, myStyleDark, myStyleLight } from "../dist/helpers"

const foundItems = [
  {
    date: "Nov 5th 2023",
    title: "UserInYrFace",
    link: "https://userinyerface.com",
    context: "If you are looking for insperation on how to design the most user friendly websites, look no further 😂"
  },
  {
    date: "Jan 1st 2023",
    title: "David Mount Independent Studies Homepage",
    link: "http://www.cs.umd.edu/~mount/Indep/index.html",
    context: "I was actually featured on this page for my college studies project. I wonder if its been updated recently... See the second entry titled 'VR Outrun Shooter by Andrew Dilks (Fall 2016). '"
  },
  {
    date: "Jan 8th 2022",
    title: "Active Theory",
    link: "https://activetheory.net"
  },
  {
    date: "Jan 8th 2022",
    title: "3js Examples",
    link: "https://threejs.org/examples/#webgl_animation_keyframes"
  },
];

const foundContentElements = (finalIsDarkMode) => (
  <>
    <div className="col-md-6 p-3 container">
      <div className="h-100 p-5"
        style={ finalIsDarkMode ? myStyleDark : myStyleLight }
      >
        {
        foundItems.map((item, index) => {
          return (
            <div key={index}>
              <li className={ `${ finalIsDarkMode ? "text-white" : "" }` } >
                { item.date }
                <br/>

                <a
                  style={{ color: "black", paddingLeft: "1rem", fontSize: "large" }}
                  href={ item.link }
                  className={ `${ finalIsDarkMode ? "text-white" : "" }` }
                >
                  { item.title }
                </a>
                <br />
                <br />

                <p className={ `${ finalIsDarkMode ? "text-white" : "" }` }>
                  { item.context }
                </p>
                <hr style={{color: `${ finalIsDarkMode ? "white" : "" }` }} />
              </li>
            </div>
          )
        })
      }
      </div>
    </div>
    <br />
  </>
)

export default function Found(props) {
  const { finalIsDarkMode } = props;

  return (
    <div>
      <center>
        { BWHeader(
            finalIsDarkMode,
            "Found",
            "Here lies all of the content that I've found over the years that sparked my joy"
        ) }
        <br />
        { foundContentElements(finalIsDarkMode) }
        { space18 }
      </center>
    </div>
  )
};
