import { ConditionalSnow, space18, BWHeader, myStyleDark, myStyleLight } from "../dist/helpers";
import { useState } from "react"


const changelogListItems = [
  {
    title: '1.0 Launch of andrewdilks.com',
    description:
      'The site is born',
  },
  {
    title: '1.1 Introduction of changelog page',
    description:
      "you're looking at it",
  },
  {
    title: '1.2 Addition of amplify UI and fresh build',
    description:
      "This will lead the design language of the site into the nearterm future",
  },
  {
    title: '1.3 Let it snow! ❄️ 🎅🏻 🏂',
    description:
      "Added snow effects. Per Alannah's request, now controllable via button",
  },
  {
    title: '1.4 Transparency and sizing',
    description:
      "makes it look cooler lol",
  },
  {
    title: '1.5 Now on your favorite search engine',
    description:
      "Google finally got around to indexing my site. Did you know you have to onboard to google? Seems like it kinda defeats the purpose",
  },
  {
    title: '1.6 Added "Found" section to website',
    description:
      "I heard in this youtube video it can be fun to collect things you've found interesting over the years, so I decided to start looking for" +
      " and sharing cool stuff",
  },
  {
    title: '1.7 Swapped out with fresh build',
    description:
      "This brings the page performance to 98 point according to chrome lighthouse 🤯. " +
      "Most of this is due to removing the aws-amplify npm package which was massive. " +
      "Currently the largest asset is bootstrap. I may try to take that out as well"
  },
  {
    title: '1.8 Added Fivrr Logo',
    description:
      "Adds some depth to the navbar as well. Still working on dynamic sizing."
  },
  {
    title: '1.9 Added Auto Detect Dark/Light mode ☀️ 🌙',
    description:
      "Now the site will display dark mode or light mode based on your mobile phone or desktops preferences"
  },
  {
    title: '2.0 Added User overridable Dark/Light mode ☀️ 🌙',
    description:
      "In addition to auto displaying dark mode or light mode based on your mobile phone or desktops preferences, " +
      "you can also toggle the setting yourself. More power to the user!",
    date: "May 27th 2022"
  },
  {
    title: '2.2 Added Experimental section.',
    description:
      "While I was playing Pokemon Soul Silver I realized its really hard to view what pokemon look like when they follow you. " +
      "This, plus the fact that you can only have one follow you at a time lead me to consider building a custom solution where you can. " +
      "So I decided to try and learn how to animate sprites with react. I've got that part working so far, but still need to add the ability to " + 
      "control their direction with the arrow keys. Dropshaddow is needed and some sort of way to have them step one tile forward. " +
      "Oh also manually processing the images takes forever, so looking for a way to automate that.",
      date: "June 11th 2022"
  },
  {
    title: '2.3 Updated Navbar.',
    description:
      "While this is mainly a bug fix, it took a lot of work which is why its making an appearance on this page. Mainly Mobile focused, " +
      "you can now have the navbar auto collapse when you click on a route. This is partially just a bug fix that came as a side effect of " +
      "moving some application logic to a higher level of abstraction to reduce the amount of repeated code, check the latest github stats, over 200" +
      "lines removed due to this refactor.",
    date: "June 12th 2022"
  },
  {
    title: '2.4 Newly Updated Navbar Part 2.',
    description:
      (<>
        <p>
          The navbar is now sticky! When you scroll past a certain point the navbar will remain on top for the rest of the page scroll. This also sets
          it up to be used with images in the background in the near future. If you've noticed, transparcenty effects are applied based on how far you scroll as well.
          All of these changes are inspired by the <a href="https://jamesedition.com" target="_blank" rel="noreferrer">JamesEdition</a> UI which looks really apealing to my eyes. Background images and more
           effects to come!
        </p>
      </>),
    date: "Jan 1st 2023"
  },
  {
    title: '2.5 Adding Dates to Entires and UI Refresh 🫧',
    description:
      (<>
        <p>
          Alright! Now that I've got my dev setup back up and running I can really start cranking out changes. So firstly I wanted to add
          dates to my posts as a way to give some temporality to the site. I've noticed one of the first things I look at when reading something
          online is the date in which it was authored. Additionally I recently recieved some feedback that the boarders on the sections around the site
          are inconsistant and that the homepage style is perfered.
        </p>
      </>),
    date: "Jan 7th 2023"
  },
  {
    title: '2.6 👾 Updated Experimental Section',
    description:
      (<>
        <p>
          I've taken the time to clean up some of this code to make itteration easier in the future. All objects can now be controlled via keyboard input.
          Additionally you can alternate between walking vs standing. The goal in the future is to be able to allow the user to select a number from a dropdown
          and have that add a new object to the canvas. A shiny toggle as well as walking toggle would be a nice to have.
        </p>
      </>),
    date: "Nov 5th 2023 (✨NEW!✨)"
  },
].reverse();

const changelogElements = (changelogListItems, finalIsDarkMode) => (
  <div className="container py-4" style={{ position: "relative" }}>
    <div className="row align-items-md-stretch">
      {
        changelogListItems.map((item, index) => {
          return (
            <div className="col-md-6 p-3" key={index}>
              <div
                className="h-100 p-3"
                style={ finalIsDarkMode ? myStyleDark : myStyleLight }
              >
                <h1 className={ `p-2 ${ finalIsDarkMode ? "text-white" : "" }` } level={3} >
                  { item.title }
                </h1>
                <p className={ `p-2 ${ finalIsDarkMode ? "text-white" : "" }` } >
                  { item.date ? item.date : "" }
                </p>
                <p className={ `p-2 ${ finalIsDarkMode ? "text-white" : "" }` } >
                  { item.description }
                </p>
              </div>
            </div>
          )
        })
      }
    </div>
  </div>
);

export default function Changelog(props) {
  const [snowIsEnabled] = useState(false);
  const { finalIsDarkMode } = props;

  return (
    <div>
      <center>
        { ConditionalSnow(snowIsEnabled) }
        { BWHeader(finalIsDarkMode, "Changelog", "Welcome to the changelog page. Here you should be able to find out all recent changes made to this site. Enjoy! 🚀") }
        <br/>

        { changelogElements(changelogListItems, finalIsDarkMode) }
        <br/>

        { space18 }
      </center>
    </div>
  );
}