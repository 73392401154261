import React from 'react'
import './App.css'
import { space6 } from './dist/helpers'
import { LandingPage } from './dist/LandingPage'

function App(props) {
  console.log("Welcome to Andrew Dilks .com!!")
  const { finalIsDarkMode } = props;

  return (
    <>
      <div>
        { LandingPage(finalIsDarkMode) } 
        { space6 }
        { space6 }
      </div>
    </>
  )
}

export default App;
