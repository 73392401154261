import { myStyleDark, myStyleLight } from "../dist/helpers"
import blue from "../images/arduinoProject1/blue.jpeg"
import green from "../images/arduinoProject1/green.jpeg"
import red from "../images/arduinoProject1/red.jpeg"
import zoom from "../images/arduinoProject1/zoom.jpeg"

const SpecialImage = (image) => (
  <div
    style={{
      display: "inline",
      paddingBottom: "5px",
      paddingRight: "5px"
    }}
  >
    <a href={ image }>
      <img src={ image } alt="logo"
        style={{
          maxWidth: "25%",
          minWidth: "10%",
          border: "5px solid #1111",
          backgroundColor: "white",
        }}
      />
    </a>
  </div>
)

const items = [
  {
    index: 0,
    head: "First entry: Learning how to acquire a captive audience",
    body: "There are many things in this world fighting for our attention. " +
      "Especially crap ideas like NFTs and cryptocurrency. " +
      "While I am not especially entertained by things like that, I do however, like to watch content dunking on crypto scams :)",
    variation: "outlined",
    date: "2022"
  },
  {
    index: 1,
    head: "Second entry: Learning how to program microcontrollers and embedded devices",
    body: "RasberryPi and ArduinoUno are some of the most popular devices you can get out there on the market today. " +
      "Which is why I fully believe these will be among my next impulse buys of 2022. What will you " +
      "create with them you might ask. Not exactly sure. But to be fair, when I created this website, " +
      "I also did not yet know its purpose. Sometimes it's better to just let things come to you. ",
    variation: "elevated",
    date: "2022"
  },
  {
    index: 2,
    head: "Third entry: First stab at Arduino, buttons, breadboards, and LEDs",
    body: "Here is my first step on the journey to creating a desktop stock ticker + graph and other related side projects like a crystal ps4 controller led conversion kit. In order to be able to tackle projects that large I figured it’d be best to start learning the fundamentals. So that’s basically what this is, just a demonstration of proficiency in wiring basics, using a breadboard, gaining back my soldering skills, and remembering how to write code for the Arduino. One thing I really like about this project is that there are many pieces that must work in order for something so seemingly simple to be pulled off.",
    body2: "All this device really does is allow for a person to press a button and have that randomly cycle the colors of the LEDs. But behind the scenes there was a lot that went into making that happen. One fun issue I ran into while making this invention was that when I purchased the LEDs, they were not soldered to breadboard legs or standoffs (not sure what called). So I first had to solder those onto the chips, and then from there get them onto the breadboard and wired up. Another issues that I’ve still yet to be able to figure out is that these particular LEDs have the ability to have the data, voltage, and ground passed through them, but that wasn’t working for me and the spec sheet doesn’t have a lot of depth. Another learning I had was figuring out how to read data into the Arduino. This was my first time creating code for the device that would change how it behaves based on input. In the images you can see I’ve used jumper wires in an attempt to clean things up as much as possible. One thing that annoys me is the resistor next to the button is poking out so much.",
    body3: "I’d really like for the ability to leave everything wired up, but that would require me purchasing an entire new Arduino which would get expensive very quickly. So I’ll probably need a way document which pins are plugged in where before I start on the next project. ",
    variation: "elevated",
    date: "2022"
  },
].reverse();


const BlogCardList = (props) => {
  const { finalIsDarkMode } = props;

  return (
    <div
      style={{
        paddingRight: "5%",
        paddingLeft: "5%"
      }}
    >
      <div>
        { items.map( item => { return (
          <div key={item.head}>
            <br/>
            { item.index === 2 ? (
              <>
              { SpecialImage(blue) }
              { SpecialImage(green) }
              { SpecialImage(red) }
              <br/>
              <br/>
              { SpecialImage(zoom) }
              <br/>
              </>
            )
            : "" }
            <br/>
            <br/>
            <div className="col-md-6">
              <div className="h-100 p-5"
                style={ finalIsDarkMode ? myStyleDark : myStyleLight }
              >
                <h2 className={ `${ finalIsDarkMode ? "text-white" : "" }` } > { item.head } </h2>
                <p className={ `${ finalIsDarkMode ? "text-white" : "" }` } > { "Date authored: " + item.date } </p>
                <p className={ `${ finalIsDarkMode ? "text-white" : "" }` } > { item.body } </p>
                { item.body2 ? 
                  (<p className={ `${ finalIsDarkMode ? "text-white" : "" }` } > { item.body2 } </p>)
                : "" }
                { item.body3 ? 
                  (<p className={ `${ finalIsDarkMode ? "text-white" : "" }` } > { item.body3 } </p>)
                : "" }
              </div>
            </div>
          </div>
        ) }) }
      </div>
    </div>
  );
};

export default BlogCardList;