import { xpTheme } from "./xpTheme";
import { center, space18 } from "../dist/helpers";

export default function XPComponent() {
    return (
      <>
        { center(<h2>Win XP Theme</h2>) }
        <div>
            <link rel="stylesheet" href="https://unpkg.com/xp.css" />
            { xpTheme() }
            { space18 }
        </div>
      </>
    );
  }