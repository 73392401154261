import BlogCardList from "./BlogCardList"
import { useState } from "react"
import { ConditionalSnow, space6, BWHeader } from "../dist/helpers"

export default function Blog(props) {
  const [snowIsEnabled] = useState(false);
  const { finalIsDarkMode } = props;

  return (
    <div>
      <center>
        { ConditionalSnow(snowIsEnabled) }
        { BWHeader(finalIsDarkMode, "Blog", "Here you can checkout some of my recent projects / opeds") }
        <br/>
        <BlogCardList {...{finalIsDarkMode}}/>
        { space6 }
        <br/>

      </center>
    </div>
  )
};
