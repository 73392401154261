import Snowfall from 'react-snowfall'
import SnowStorm from 'react-snowstorm';



export const myStyleDark = {
  backgroundColor: "rgba(33, 37, 41, 0.8)",
  borderColor: "white",
  borderWidth: "thick",
  border: "5px solid white",
  borderRadius: "25px",
};

export const myStyleLight = {
  backgroundColor: "aliceblue",
  borderWidth: "thick",
  border: "5px solid black",
  borderRadius: "25px",
}


export const BWHeader = (finalIsDarkMode, mainText, contentText) => (
  <>
    <div className="container">
      <div
        className="p-3"
        style={ finalIsDarkMode ? myStyleDark : myStyleLight }
      >
        <div className="container-fluid">
          <h1 className={ `display-5 fw-bold ${ finalIsDarkMode ? "text-white" : "" }` }>
            { mainText }
          </h1>
          <br/>

          <h3 className={ `${ finalIsDarkMode ? "text-white" : "" }` }>
            { contentText }
          </h3>
        </div>
      </div>
    </div>
  </>
);

export const center = (element) => {
  return (
    <div style={{ "display": "flex", "justifyContent": "center"}} >
      { element }
    </div>
  )
}

export const ConditionalSnow = (snowIsEnabled) => (
  snowIsEnabled ?
    (<>
      <SnowStorm
        excludeMobile={false}
        followMouse={false}
      />
      <Snowfall />
    </>)
    : <></>
)

export const space6 = (
  <>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
  </>
);

export const space3 = (
  <div>
    <br/>
    <br/>
    <br/>
  </div>
);


export const space18 = (
  <>
    { space6 }
    { space6 }
    { space6 }
  </>
);

