import React, { Component } from 'react';
import ArrowKeysReact from 'arrow-keys-react';
import { PokeRender } from "./PokeRender";

class ArrowController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: 'Use arrow keys on your keyboard!'
    };

    ArrowKeysReact.config({
      left: () => {
        this.setState({
          content: 'left key detected.'
        });
      },
      right: () => {
        this.setState({
          content: 'right key detected.'
        });
      },
      up: () => {
        this.setState({
          content: 'up key detected.'
        });
      },
      down: () => {
        this.setState({
          content: 'down key detected.'
        });
      }
    });
  }

  setUp() {
    this.setState({
      content: 'up key detected.'
    });
  }

  setDown() {
    this.setState({
      content: 'down key detected.'
    });
  }

  setLeft() {
    this.setState({
      content: 'left key detected.'
    });
  }

  setRight() {
    this.setState({
      content: 'right key detected.'
    });
  }

  render() {
    return (
      <div {...ArrowKeysReact.events} tabIndex="1">
        {this.state.content}
        <br/>
        <button onClick={() => this.setUp()}> up </button>
        <button onClick={() => this.setDown()}> down </button>
        <button onClick={() => this.setLeft()}> left </button>
        <button onClick={() => this.setRight()}> right </button>
        <PokeRender direction={this.state.content} />
      </div>
    );
  }
}

export default ArrowController;