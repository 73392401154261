import { Link } from "react-router-dom";
import AndrewDilksIcon from "../images/AndrewDilksIcon.png"
import AndrewDilksIconDark from "../images/AndrewDilksIconDark.png"
import { myStyleDark, myStyleLight } from "./helpers";

const mainText = "I've created this website as a way to demonstrate my coding abilities and to grow my understanding of "+
  "open source UI library components that are are fun to work with." +
  "I'll add some thoughts and other tech related content. As you may be able to tell, this site" +
  "is very much under active development.";

const mainHeader = "Welcome"

const subHeader = "You have now visited AndrewDilks.com!"

const iconMaxWidth = "35%"

const firstButtonText = "Checkout changelog"

const secondButtonText = "Checkout some Cool websites I've found"

export const lightImage = (
  <img src={ AndrewDilksIcon } alt="logo"
      style={{
        maxWidth: iconMaxWidth,
        minWidth: "10%",
        border: "5px solid black",
        borderRadius: "20px",
        backgroundColor: "white",
        float: "right"
      }}
    />
)

export const darkImage = (
  <img src={ AndrewDilksIconDark } alt="logo"
    style={{
      maxWidth: iconMaxWidth,
      minWidth: "10%",
      border: "5px solid white",
      borderRadius: "20px",
      backgroundColor: "rgba(33, 37, 41, 0.8)",
      float: "right",
    }}
  />
)



const sectionOneDark = (dark) => (
  <div id="navs2" className="p-4 mb-4" style={ dark ? myStyleDark : myStyleLight } >

    <div className="container-fluid pt-4 pb-5">

      <>
        <div style={{
          float: "left",
          paddingLeft: "10px",
        }}>
          <h1 className={"display-5 fw-bold" + (dark ? " text-white" : "") } >
            { mainHeader }
          </h1>
        </div>

        <div style={{ paddingLeft: "10px",  }}>
          { dark ? darkImage : lightImage }
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
      </>


      <h2 className={"display-7" + (dark ? " text-white" : "")} style={{ paddingLeft: "10px", marginBottom: "0" }}>
        { subHeader }
      </h2>
      <br />

      <p className={"col-md-10" + (dark ? " text-white" : "") } style={{ padding: "10px" }}>
        { mainText}
      </p>
      <br />

      <center>
        { dark ?
          (
            <>
            <Link to="/changelog" style={{paddingRight: "1rem" }}>
              <button className="btn btn-light" type="button" style={{marginTop: "1rem"}}>
                { firstButtonText }
              </button>
            </Link>

            <Link to="/found" style={{paddingRight: "1rem" }}>
              <button className="btn btn-light" type="button" style={{marginTop: "1rem"}}>
                { secondButtonText }
              </button>
            </Link>

            </>
          ) :
          (<>
            <Link to="/changelog" style={{paddingRight: "1rem" }}>
              <button className="btn btn-light" type="button" style={{
                marginTop: "1rem",
                "borderWidth": "thick",
                "border": "3px solid black",
                "borderRadius": "15px",
              }}>
                { firstButtonText }
              </button>
            </Link>

            <Link to="/found" style={{paddingRight: "1rem" }}>
              <button className="btn btn-light" type="button" style={{
                marginTop: "1rem",
                "borderWidth": "thick",
                "border": "3px solid black",
                "borderRadius": "15px",
              }}>
                { secondButtonText }
              </button>
            </Link>
          </>)
        }
      </center>
    </div>
  </div>
);

const sectionTwo = (
  <div className="col-md-6 p-2">
    <div className="h-100 p-5 text-white" style={myStyleDark}>
      <h2> Check under the hood </h2>
      <p>
        Create React App, React-Router, github, aws s3, aws route53, 
        namecheap, bootstrap, mui, and xp github css files
      </p>
      <Link to="/blog">
        <button className="btn btn-outline-light btn-lg" type="button">
          Checkout my Blog
        </button>
      </Link>
    </div>
  </div>
);

const sectionThreeContent = (
  <>
    <h2> Custom built for ios and Apple devices </h2>
    <p>
      If you have any ideas for fun things to do with websites please let me know. 
      I have a few hackathon project ideas I might be able to use...
    </p>
  </>
)


const sectionThree = (dark) => (
  <div className="col-md-6 p-2">
    <div className={ dark ? "h-100 p-5 text-white" : "h-100 p-5" } style={ dark ? myStyleDark : myStyleLight }>
      { sectionThreeContent }
    </div>
  </div>
);


export const LandingPage = (finalIsDarkMode) => {

  return (
    <div>
      <div className="container py-4" style={{ position: "relative" }}>
        { sectionOneDark(finalIsDarkMode) }

        <div className="row align-items-md-stretch">
          { sectionTwo }

          { sectionThree(finalIsDarkMode) }
        </div>

        <footer className="pt-3 mt-4 text-muted border-top">
        </footer>
      </div>

    </div>
)}